import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import Container from "../../components/Container/Container";
import { setDetailFromCargo } from "../../store/detail/actions";
import { useDispatch } from "react-redux";
import axiosGet from "../../helpers/axiosGet";
import axios from "axios";
import constants from "../../constants/constants";
import Button from "../../components/Button/Button";

const Home = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  // get queries
  const token = searchParams.get("token");
  const userCode = searchParams.get("user");
  const data = searchParams.get("data");
  const type = searchParams.get("type");
  const refId = searchParams.get("refId");
  const uniqId = searchParams.get("uniqId");
  const isClaimed = searchParams.get("isClaimed");
  const signature = searchParams.get("signature");
  const isKalog = searchParams.get("isKalog") && searchParams.get("isKalog").toUpperCase() == 'TRUE' ? true : false;

  const setBaseURL = () => {
    const signature = sessionStorage.getItem("signature");
    axios.defaults.baseURL = signature
      ? constants.GATEWAY_URL
      : constants.DEFAULT_URL;
  };

  useEffect(() => {
    if (token && userCode) {
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("userCode", userCode);
      sessionStorage.setItem("type", type);

      if (data) {
        // replace whitespace with '+'
        const replace = data.replace(/\s/g, "+");

        // decode base64
        const decode = window.atob(replace);

        // parse to json
        const parse = JSON.parse(decode);
        dispatch(setDetailFromCargo(parse));

        setTimeout(() => {
          navigate("/klaim");
        }, 100);
      } else if (type && type === "list") {
        setTimeout(() => {
          navigate("/riwayat");
        }, 100);
      } else {
        setTimeout(() => {
          navigate("/klaim/check");
        }, 100);
      }
    } else if (userCode && signature) {
      sessionStorage.setItem("signature", signature);
      sessionStorage.setItem("userCode", userCode);
      sessionStorage.setItem("isKalog", isKalog);
      sessionStorage.setItem("type", type);
      setBaseURL();
      if (refId) {
        axiosGet({
          url: `/polismall-claim-cargo/declaration/ref-id/${refId}`,
          callback: (res) => {
            const data = res.data;
            dispatch(setDetailFromCargo(data));

            setTimeout(() => {
              if (isKalog) {
                navigate("/klaim-kalog");
              } else {
                navigate("/klaim");
              }
            }, 1000);
          },
          errorCallback: (err) => {
            setError(err);
            console.log(err);
          },
        });
      } else if (uniqId && isClaimed) {
        setTimeout(() => {
          if(isKalog){
            navigate(`/klaim-kalog/riwayat/${uniqId}`);
          } else {
            navigate(`/riwayat/${uniqId}`);
          }
        }, 100);
      } else if (type && type === "list") {
        setTimeout(() => {
          navigate("/riwayat");
        }, 100);
      } else {
        setTimeout(() => {
          navigate("/klaim/check");
        }, 100);
      }
    } else {
      setTimeout(() => {
        navigate("/404");
      }, 100);
    }
  }, [
    data,
    dispatch,
    navigate,
    token,
    type,
    userCode,
    refId,
    signature,
    isKalog,
    isClaimed,
    uniqId,
  ]);

  if (error) {
    return (
      <Container>
        <div className="vh-80 d-flex flex-column align-items-center justify-content-center fw-bold text-danger">
          <div
            className="alert alert-danger"
            role="alert"
            style={{ fontSize: "1rem", width: "100%", textAlign: "center" }}
          >
            {error?.message}
          </div>

          <Button
            onClick={() => window.location.reload()}
            style={{ borderRadius: "5px !important" }}
          >
            Reload
          </Button>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <Spinner />
    </Container>
  );
};

export default Home;
