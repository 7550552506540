import { useCallback, useEffect, useState } from "react";

const TextAreaMultipleOptions = ({ name, setValue, label, register, options, required }) => {
    const [isFocusTAM, setIsFocusTAM] = useState(false);
    const [TAMValue, setTAMValue] = useState();
    const extendedOptions = options;
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [selectedAll, setSelectedAll] = useState(false);

    const onFocusTextAreaMultiple = (isFocus = false) => {
        setIsFocusTAM(isFocus);
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            setIsFocusTAM(false);
            //Do whatever when esc is pressed
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);
    
    const customStyle = {
        button: {
            border: 'none', background: 'none',
            textTransform: 'uppercase', color: '#1486a3', fontSize: "13px",
            padding: "5px 10px"
        }
    }

    const addSelectedOption = (value) => {
        let tmp = selectedOptions;
        if (tmp.includes(value))
            tmp = tmp.filter((x) => x !== value);
        else
            tmp.push(value);
        setSelectedOptions(tmp);
        onFocusTextAreaMultiple(true);
        setTAMValue(tmp.join(","));
        setValue(name, tmp.join(","));
    }

    return <div style={{ position: 'relative' }}>
        <textarea
            id={name}
            rows="2"
            value={TAMValue}
            onChange={(e) => { setTAMValue(e.target.value) }}
            onClick={() => onFocusTextAreaMultiple(true)}
            className={`form-control form-control-lg txtMulti`}
            {...register(name, {
                required: required && `${label} is required`,
            })}
        ></textarea>
        {extendedOptions.length > 0 &&
            <div id={`selector-${name}`} style={{
                scrollbarWidth: 'thin',
                backgroundColor: "#fff", border: "1px solid #333",
                zIndex: 999, width: "100%", display: isFocusTAM ? 'block' : 'none', position: 'absolute', top: '105px'
            }}
            >
                <input placeholder={`Filter ${label}`} type="text" style={{ paddingLeft: "10px", width: "100%" }}
                    onChange={(e) => {
                        const tmp = extendedOptions.filter(x => x.name.toLowerCase().includes(e.target.value.toLowerCase()));
                        setFilteredOptions(tmp);
                    }}></input>
                <div style={{
                    maxHeight: "200px", overflow: "scroll",
                }}>
                    {filteredOptions.map((option) => (
                        <div
                            className="item-multi"
                            style={{
                                backgroundColor: selectedOptions.includes(option.value) ? "#ddd" : "#fff",
                                cursor: 'pointer',
                                padding: "5px 10px",
                                display: "flex",
                                // height: '30px'
                            }} key={option.name} value={option.value}>
                            <div style={{ flex: "95%", display: 'flex' }} onClick={() => {
                                addSelectedOption(option.value);
                                setSelectedAll(false);
                            }} >
                                <input id={`chk-${option.value}`} type="checkBox" checked={selectedOptions.includes(option.value)} style={{
                                    flex: "5%", accentColor: '#f50057', borderRadius: '5px', marginRight: '10px', marginTop: '2px', width: '18px', height: '18px'
                                }}>
                                </input>
                                <div style={{ flex: "95%" }}>{option.name}</div></div>
                            {/* {selectedOptions.includes(option.value) &&
                                <div onClick={() => { removeSelectedOption(option.value); }}
                                    style={{ flex: "5%", color: "red", textAlign: "right", fontSize: "11px" }}>X</div>
                            } */}
                        </div>
                    ))}
                </div>
                <div style={{ textAlign: "center" }}>
                    <button type='button' className="simple-button" onClick={() => {
                        const newValueSelectedAll = !selectedAll;

                        setSelectedOptions(newValueSelectedAll ? extendedOptions.map(x => x.value) : []);
                        onFocusTextAreaMultiple(false);
                        const newVal = newValueSelectedAll ? extendedOptions.map(x => x.value).join(",") : [];
                        setTAMValue(newVal);
                        setValue(name, newVal);
                        setSelectedAll(newValueSelectedAll);
                    }} style={customStyle.button}>{selectedAll ? "Deselect All" : "Select All"}</button>
                    <button className="simple-button" type='button' style={customStyle.button} onClick={() => {
                        onFocusTextAreaMultiple(false);
                    }}>Close</button>
                </div>
            </div>
        }
    </div>
}

export default TextAreaMultipleOptions;