import { useState } from "react"
import { Link } from "react-router-dom"

export default function CollapsibleDetailField(text) {
  const [collapsed, setCollapsed] = useState(true);
  if(text) {
    const generate = () => {
      return <>{collapsed ? text.substring(0, 140) + "  ...." : text}
        <Link style={{ padding: "0 5px", fontWeight: "normal", display: "inline-block" }} className="small" to={'#'} onClick={() => { setCollapsed(!collapsed) }}>
          {collapsed ? "Show More" : "Show Less"}
        </Link>
      </>
    }

    return <>
      {text.length > 140 ? generate() : text}
    </>
  } else {
    return '';
  }
}
