import {
  MDBBtn,
  MDBCol,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
  MDBRow,
} from "mdb-react-ui-kit";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDataFormOne } from "../../store/klaim/actions";

import Button from "../../components/Button/Button";
import ScrollToHere from "../../components/ScrollToHere";
import Spinner from "../../components/Spinner/Spinner";
import Subheading from "../../components/Subheading/Subheading";
import axiosGet from "../../helpers/axiosGet";
import CertificateDetailKalog from "../../components/Detail/CertificateDetailKalog";
import FormKalog from "../../components/Form/FormKalog";

const formId = "claim-form-one";

export default function FormOneKalog() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dataFormOne } = useSelector(
    (state) => state.klaimStore
  );
  const { detailFromCargo } = useSelector((state) => state.detailStore);

  const header = detailFromCargo.insured || "";

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);
  const [dataInvoice, setDataInvoice] = useState(null);

  useEffect(() => {
    axiosGet({
      url: `/claim-cargo/invoice/${detailFromCargo?.policyNumber}-${detailFromCargo?.certificateNumber}`,
      callback: (res) => {
        setDataInvoice(res.data);
      },
      errorCallback: (res) => {
        console.log(res);
      },
    });
  }, [detailFromCargo?.certificateNumber, detailFromCargo?.policyNumber]);

  const handleNext = (data) => {
    ScrollToHere();
    setLoading(true);
    setError(null);

    dispatch(setDataFormOne(data));

    // const body = {
    //   productType: detailFromCargo.productCode,
    //   companyName: detailFromCargo.companyName,
    //   policyNumber: detailFromCargo.policyNumber,
    //   conveyance: detailFromCargo.mainVessel,
    //   certificateNo: detailFromCargo.certificateNumber,

    //   typeOfLoss: data.typeOfLoss || null,
    //   accidentType: data.accidentType || null,
    //   accidentDate: data.accidentDate,
    //   accidentDetail: data.accidentDetail,
    //   accidentLocation: data.accidentLocation,

    //   picName: data.picName,
    //   picPhoneNumber: data.picPhoneNumber,
    //   picEmail: data.picEmail,
    //   containerNo: data.containerNo,
    //   claimAmount: data.claimAmount,
    // };

    // // jika claimCargoId udah ada, hit api update biar gak bikin claim baru
    // if (claimCargoId) {
    //   axiosPut({
    //     url: `/claim-cargo/update/${claimCargoId}`,
    //     data: body,
    //     callback: () => {
    //       setLoading(false);

    //       const encode = window.btoa(claimCargoId);
    //       navigate(`/klaim/new/${encode}/next`);
    //     },
    //     errorCallback: (err) => {
    //       setLoading(false);
    //       setModal(true);
    //       setError(err);
    //     },
    //   });
    // } else {
    //   // jika belum, bikin claim baru
    //   axiosPost({
    //     url: "/claim-kalog/save",
    //     data: body,
    //     callback: (res) => {
    //       setLoading(false);
    //       // console.log(res);
    //       //   dispatch(setClaimCargoId(res.data.claimCargoId));
    //       const uniqId = res.data.uniqId;
    //       navigate(`/klaim-kalog/new/${uniqId}/summary`);
    //     },
    //     errorCallback: (err) => {
    //       setLoading(false);
    //       setModal(true);
    //       setError(err);
    //     },
    //   });
    // }

    navigate(`/klaim-kalog/new/${detailFromCargo.uniqId}/summary`);
  };

  const inputs = [
    {
      name: "claimAmount",
      label: "Estimasi Kerugian",
      type: "currency",
      required: true,
    },
    {
      name: "accidentDate",
      label: "Tanggal Kejadian",
      type: "date",
      required: true,
    },
    {
      name: "picName",
      label: "PIC Nama CS Outlet",
      type: "text",
      required: true,
    },
    {
      name: "picEmail",
      label: "PIC Email Pelanggan",
      type: "email",
      required: true,
    },
    {
      name: "picPhoneNumber",
      label: "PIC No Handpone Pelanggan",
      type: "number",
      required: true,
      minLength: 8,
      maxLength: 13,
    },
    {
      name: "accidentDetail",
      label: "Detail Kronologi Kejadian",
      type: "textarea",
      required: true,
    },
  ];

  const conditionalInputs = [
    {
      name: "accidentType",
      label: "Jenis Kejadian",
      type: "radio",
      required: true,
      options: [
        {
          name: "damageCargo",
          label: "Damage Cargo",
          value: "DAMAGE",
          tooltip:
            "Damage Cargo adalah kargo yang ditemukan dalam keadaan rusak baik itu untuk kerusakan packing, isi, mutu dari kargo itu sendiri.",
        },
        {
          name: "shortageCargo",
          label: "Shortage Cargo",
          value: "SHORTAGE",
          tooltip:
            "Shortage Cargo adalah kargo yang ditemukan dalam keadaan barang yang kurang pada saat barang tersebut sampai tujuan. Dalam hal ini, penyelesaiannya dilakukan berdasarkan Harga Pertanggungan dari barang yang kurang diserahkan tersebut.",
        },
      ],
    },
  ];

  return (
    <section className="overflow-hidden">
      {loading ? (
        <Spinner height={70} />
      ) : (
        <>
          <MDBRow className="g-4">
            <MDBCol lg="8">
              <Subheading>Detail Klaim</Subheading>

              <FormKalog
                header={header}
                id={formId}
                handleNext={handleNext}
                defVals={dataFormOne}
                detailFromCargo={detailFromCargo}
                dynamicInputs={inputs}
                dynamicConditionalInputs={conditionalInputs}
                kalogMicrosite={true}
              />

              {/* <button onClick={fillForm}>FILL FORM</button> */}
            </MDBCol>

            <MDBCol lg="4">
              <Subheading>Detail Certificate</Subheading>

              <CertificateDetailKalog
                detail={detailFromCargo}
                isFromCargo
                invoice={dataInvoice}
              />
            </MDBCol>
          </MDBRow>

          <div className="d-flex justify-content-end mt-5">
            <Button endIcon="arrow-right" form={formId} type="submit">
              Selanjutnya
            </Button>
          </div>
        </>
      )}

      <MDBModal show={modal} setShow={setModal} tabIndex="-1">
        <MDBModalDialog centered>
          <MDBModalContent className="bg-danger">
            <MDBModalHeader>
              <MDBModalTitle className="text-light fw-bold">
                Error
              </MDBModalTitle>
              <MDBBtn
                className="btn-close btn-close-white"
                color="none"
                onClick={toggleModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody className="text-light">
              {error && error.message}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </section>
  );
}
