import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";

import { Provider } from "react-redux";
import store from "./store";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";

import "moment/locale/id";
import constants from "./constants/constants";

import * as Sentry from "@sentry/react";

const signature = sessionStorage.getItem("signature");
axios.defaults.baseURL =
  signature && signature !== "null"
    ? constants.GATEWAY_URL
    : constants.DEFAULT_URL;

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    const userCode = sessionStorage.getItem("userCode");
    const signature = sessionStorage.getItem("signature");
    const isKalog = sessionStorage.getItem("isKalog") && sessionStorage.getItem("isKalog").toUpperCase() == 'TRUE' ? true : false;
    if (token && userCode && !isKalog) {
      config.headers["X-Authorization"] = token;
      config.headers["X-User"] = userCode;
    } else if (userCode && signature && signature !== "null") {
      config.headers["X-User"] = userCode;
      config.headers["X-Open-Signature"] = signature;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Sentry.init({
  dsn: constants.SENTRY_URL,
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
